var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.away,
            expression: "away"
          }
        ],
        staticClass: "popup-wrapper"
      },
      [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "popup__left" }, [
            _c("div", { staticClass: "popup__icon" }, [
              _c(
                "div",
                {
                  staticClass: "icon-button icon-button--rounded",
                  class: {
                    "icon-button--rounded--orange":
                      _vm.marker.type === "drop-off"
                  }
                },
                [
                  _vm.marker.type === "pickup"
                    ? _c("i", { staticClass: "ri-arrow-up-circle-line" })
                    : _c("i", { staticClass: "ri-arrow-down-circle-line" })
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "popup__right" },
            [
              _c("div", { staticClass: "popup__item popup__item-main" }, [
                _c(
                  "div",
                  {
                    staticClass: "popup__info",
                    class: { "popup__info-top": _vm.marker.attachmentPath }
                  },
                  [
                    _c("div", { staticClass: "popup__title" }, [
                      _vm._v(_vm._s(_vm.$dots(_vm.marker.customerName, 60)))
                    ]),
                    _c("div", { staticClass: "popup__address" }, [
                      _vm._v(_vm._s(_vm.marker.customerAddress))
                    ]),
                    _vm.marker.attachmentPath
                      ? _c(
                          "div",
                          { staticClass: "popup__img" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: _vm.asset,
                                "preview-src-list": [_vm.marker.attachmentPath]
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm._l(_vm.customers, function(customer, i) {
                return _c(
                  "div",
                  { key: "cus" + i + customer.id, staticClass: "popup__item" },
                  [
                    _c("div", { staticClass: "popup__item-top" }, [
                      _c("div", { staticClass: "popup__icon" }, [
                        _c(
                          "div",
                          {
                            staticClass: "icon-button icon-button--round",
                            style: {
                              borderColor: customer.serviceColor,
                              backgroundColor: customer.serviceColor,
                              color: "#fff"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "ri-arrow-down-circle-line"
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "popup__info" }, [
                        _c("div", { staticClass: "popup__title" }, [
                          _vm._v(_vm._s(_vm.$dots(customer.name, 26)))
                        ]),
                        _c("div", { staticClass: "popup__address" }, [
                          _vm._v(_vm._s(customer.address))
                        ])
                      ]),
                      _vm.marker.type === "pickup"
                        ? _c(
                            "div",
                            { staticClass: "popup__btn" },
                            [
                              !customer.status
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button button--done",
                                      on: {
                                        click: function($event) {
                                          return _vm.changeStatus(
                                            1,
                                            customer.deliveryID
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ri-checkbox-circle-fill"
                                      }),
                                      _c("span", [_vm._v("Not Done")])
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button button--fill button--green"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ri-checkbox-circle-fill"
                                      }),
                                      _c("span", [_vm._v("Done")])
                                    ]
                                  ),
                              !customer.status
                                ? _c("i", { staticClass: "ri-more-2-fill" })
                                : _c(
                                    "el-dropdown",
                                    {
                                      attrs: { trigger: "click" },
                                      on: { command: _vm.action }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ri-more-2-fill"
                                      }),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass:
                                            "el-popper-custom el-popper--actions",
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              staticClass: "item--edit",
                                              attrs: {
                                                command: {
                                                  id: customer.deliveryID,
                                                  status: 0
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "ri-arrow-go-back-fill"
                                              }),
                                              _vm._v(" Undo ")
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "popup__btn" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "button button--done",
                                  class: {
                                    "button--disabled": customer.status !== 1,
                                    "button--green": customer.status === 2
                                  },
                                  attrs: {
                                    disabled:
                                      customer.status === 1 ? false : true
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeStatus(
                                        2,
                                        customer.deliveryID
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "ri-checkbox-circle-fill"
                                  }),
                                  customer.status === 2
                                    ? _c("span", [_vm._v("Done")])
                                    : _c("span", [_vm._v("Not Done")])
                                ]
                              ),
                              customer.status !== 2
                                ? _c("i", { staticClass: "ri-more-2-fill" })
                                : _c(
                                    "el-dropdown",
                                    {
                                      attrs: { trigger: "click" },
                                      on: { command: _vm.action }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ri-more-2-fill"
                                      }),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass:
                                            "el-popper-custom el-popper--actions",
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              staticClass: "item--edit",
                                              attrs: {
                                                command: {
                                                  id: customer.deliveryID,
                                                  status: 1
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "ri-arrow-go-back-fill"
                                              }),
                                              _vm._v(" Undo ")
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "popup__item-bottom",
                        class: { "popup__item-bottom--show": customer.active }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "popup__item-bottom-header",
                            on: {
                              click: function($event) {
                                return _vm.showDetails(customer)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "popup__item-bottom-title" },
                              [_vm._v("Delivery Details")]
                            ),
                            _c(
                              "div",
                              { staticClass: "popup__item-bottom-icon" },
                              [_c("i", { staticClass: "ri-arrow-down-s-line" })]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "popup__item-bottom-main" },
                          [
                            customer.notes
                              ? _c(
                                  "div",
                                  { staticClass: "popup__item-bottom-notes" },
                                  [_vm._v(_vm._s(customer.notes))]
                                )
                              : _vm._e(),
                            customer.attachmentPath
                              ? _c("el-image", {
                                  staticClass: "popup__item-bottom-image",
                                  attrs: {
                                    src: customer.attachmentPath,
                                    "preview-src-list": [
                                      customer.attachmentPath
                                    ],
                                    fit: "cover"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }