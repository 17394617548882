var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map__wrapper" },
    [
      _vm.isLoading
        ? _c("div", [_vm._v(" Fancy loader should be here ")])
        : _vm.isError
        ? _c("div", [_vm._v(" " + _vm._s(_vm.error) + " ")])
        : _vm._e(),
      _c("div", { ref: "map", staticClass: "map__inner" }),
      _vm.marker && !_vm.showMobilePopup
        ? _c("map-popup", {
            key: _vm.marker.markerID,
            style: { top: _vm.position.y, left: _vm.position.x },
            attrs: { marker: _vm.marker, position: _vm.position },
            on: { closeModal: _vm.hide, reload: _vm.loadMarkers }
          })
        : _vm._e(),
      _c(
        "el-drawer",
        {
          staticClass: "el--mobile",
          attrs: {
            visible: _vm.showMobilePopup,
            direction: "btt",
            "show-close": false,
            "with-header": false,
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showMobilePopup = $event
            }
          }
        },
        [
          _c("map-popup-mobile", {
            attrs: { marker: _vm.marker },
            on: { hideMobile: _vm.hideMobile, reload: _vm.loadMarkers }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }